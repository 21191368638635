import React from "react";
import { Link } from "gatsby";

const Privacy = () => {
  return (
    <>
      <div className="pt-23 pt-md-25 pt-lg-30 pb-9 pb-md-16 pb-lg-21">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-11">
              <div className="text-center">
                <h2 className="font-size-11 mb-8">Privacy Policy</h2>
                {/* <p className="font-size-7 mb-0">
                    Our focus is always on finding the best people to work with.
                    Our bar is high, but you look ready to take on the
                    challenge.
                  </p> */}
              </div>
            </div>
          </div>
          <div className="px-3">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-10 pr-lg-13 pr-xl-0">
                <div className="pt-lg-17 pt-8">
                  {/* <h5 className="font-size-7 mb-7">Agreement to terms</h5> */}
                  <p className="font-size-5 heading-default-color mb-0">
                    <strong>Last updated: Febuary 3rd, 2022</strong>
                    <br /> <br />
                    This Privacy Policy governs IWN Studio’s privacy practices
                    with respect to our use of information relating to your use
                    of the Lawy application (Mobile Application and Website)
                    (hereinafter the “Privacy Policy”) and is namely intended to
                    inform you on:
                    <br /> <br />
                    <ul>
                      <li>What personal information we collect from you;</li>
                      <li>
                        How we use your information and with whom we are likely
                        to share it;
                      </li>
                      <li>
                        What are your choices regarding the collection, use and
                        distribution of information;
                      </li>
                      <li>
                        What types of security procedures are in place to
                        protect your information from loss, misuse or
                        modification of the information under our control;
                      </li>
                      <li>How you can correct inaccurate information.</li>
                    </ul>
                    For your information, this Privacy Policy applies only to
                    information collected by IWN Studio as part of your use of
                    the Lawy application. This Privacy Policy does not apply to
                    any information collected online or offline by any other
                    entity, whether or not that entity is affiliated with IWN
                    Studio. All capitalized terms found in this Privacy Policy
                    have the meaning ascribed to them in the “Terms and
                    Conditions” of the Lawy application accessible through the
                    following link:{" "}
                    <Link to="http://lawy.co/terms">http://lawy.co/terms</Link>
                  </p>
                  <div className="mt-9 mt-lg-11">
                    <h5 className="font-size-7 mb-7">Security</h5>
                    <p className="font-size-5 heading-default-color">
                      We know how important your personal information is to you
                      and we take our responsibility to protect any information
                      that you provide to us very seriously. In order to protect
                      any personally identifiable information that we collect
                      about you, we have implemented physical, electronic and
                      administrative measures to prevent unauthorized access to
                      or unauthorized disclosure of your information. IWN Studio
                      maintains secure data networks protected by firewalls and
                      systems access with passwords that comply with industry
                      standards. We have anti-virus software and we update them
                      to industry standards regularly. Only authorized
                      individuals have access to and/or have the right to
                      disclose information provided by you in connection with
                      your use of the Lawy application. Such authorized
                      individuals are aware of this Privacy Policy and will use
                      your personal information in accordance with this Privacy
                      Policy. Although we take all reasonable measures to
                      protect personally identifiable information that you share
                      with us, no transmission of information via the Internet
                      can be 100% guaranteed and information stored
                      electronically is never completely immune to attack.
                      Therefore, we cannot guarantee the security of the
                      information you provide us.
                    </p>
                  </div>
                  <div className="mt-9 mt-lg-13">
                    <h5 className="font-size-7 mb-7">
                      Your Personal Information
                    </h5>
                    <p className="font-size-5 heading-default-color mb-9">
                      In order to better understand and meet the needs of the
                      Lawy application Users, IWN Studio collects your data as
                      well as personally identifiable information. Personally
                      identifiable information is information that identifies
                      you or that may be used to contact you. When you register
                      for a Lawy account, IWN Studio may collect the following
                      personally identifiable information:
                      <br />
                      <ul>
                        <li>Full Name</li>
                        <li>Email</li>
                        <li>Telephone number</li>
                        <li>Profile Picture</li>
                        <li>Business/Workspace Picture</li>
                      </ul>
                      Additional information that may be collected in order to
                      complete your profile, as well as the workspace files, are
                      also protected by this Privacy Policy, except the
                      information that you consent to make public or that is
                      already in the public domain. Moreover, IWN Studio may
                      collect the following information about your use of the
                      Lawy application:
                      <br />
                      <ul>
                        <li>Date and place of use;</li>
                        <li>Frequency of use;</li>
                        <li>Average duration of use;</li>
                      </ul>
                      Except in accordance with the application of this Privacy
                      Policy, IWN Studio guarantees the confidentiality of the
                      personal information of each User of the Lawy application.
                      IWN Studio may also collect any other information about
                      you from third-party service providers and partners
                      including those that have entered into a service agreement
                      with IWN Studio for the provision of the Lawy application.
                      IWN Studio will collect the information you provide when
                      you use the Lawy application.
                    </p>
                  </div>
                  <div className="mt-9 mt-lg-13">
                    <h5 className="font-size-7 mb-7">
                      Our Use of Your Information
                    </h5>
                    <p className="font-size-5 heading-default-color">
                      IWN Studio may use your personally identifiable
                      information in several ways: We use your information to
                      enable us, among other things, to create your Lawy
                      account, to verify the identity of Users for security
                      purposes, for statistical and marketing purposes and to
                      optimize the functionalities and modules of the Lawy
                      application. In addition, we use your information to
                      enable us to operate the Lawy application. IWN Studio may
                      hire third-party companies to provide services on its
                      behalf (e.g., hosting the Website or analyzing data). We
                      may provide your personally identifiable information to
                      such service providers so that they may perform such
                      services, and you agree that IWN Studio may share such
                      information for such purposes. We may also disclose your
                      personally identifiable information if we believe that we
                      must do so under the law; to comply with legal procedures
                      or with a government request; to enforce the “Terms and
                      Conditions” of the Lawy applicationaccessible via the
                      following link:{" "}
                      <Link to="http://lawy.co/terms">
                        http://lawy.co/terms
                      </Link>
                      ; and/or to protect the rights, property or safety of IWN
                      Studio, its Users, its partners or of the public. IWN
                      Studio may transfer or otherwise disclose your personal
                      information in connection with a merger, sale,
                      consolidation or other corporate change.
                    </p>
                  </div>
                  <div className="mt-9 mt-lg-13">
                    <h5 className="font-size-7 mb-7">
                      Your Information and the Lawy application
                    </h5>
                    <p className="font-size-5 heading-default-color">
                      It is not necessary to give us any personal information to
                      visit the Lawy application. We may, however, collect
                      personally identifying information from our Users through
                      the Lawy application, including when setting up their
                      account. If you decide to provide us with information, we
                      may provide you with information about products, services
                      and/or events that may be of interest to you. When you
                      create a Lawy account, we may ask you for certain
                      information to set up your account. You do not have to
                      create a Lawy account to access the Lawy application.
                      Please note that this Privacy Policy does not apply to any
                      information you may provide publicly in the user forums of
                      the Lawy application, as the case may be (such as chat
                      rooms, bulletin boards or similar Web pages). You should
                      keep in mind that whenever you publicly disclose
                      information about yourself online, this information may be
                      collected and used by people you do not know. We shall not
                      be liable for any actions or policies of any third parties
                      that collect any information that users may disclose in
                      such forums or other public sites, if any, of the Lawy
                      application.
                    </p>
                  </div>
                  <div className="mt-9 mt-lg-13">
                    <h5 className="font-size-7 mb-7">
                      Cookies and Web Beacons
                    </h5>
                    <p className="font-size-5 heading-default-color">
                      The Lawy application may use cookies and similar
                      technologies from time to time to better recognize you
                      when you return to the Lawy application. A cookie is a
                      small data file stored on a User’s device by a Web server.
                      Cookies are used to help us identify you when you return
                      to the Lawy application in order to adapt the information
                      you receive while browsing the Lawy application. You are
                      always free to block cookies if your device allows it, but
                      this may prevent you from accessing parts of the Lawy
                      application. Our Web pages or email messages may contain
                      small graphic images called “Web beacons” (also sometimes
                      called “pixel tags”) that allow us to track and collect
                      certain limited information about our Users on the Lawy
                      application, such as the type of device requesting the Web
                      beacon, the IP address of the device to which the Web
                      beacon is transmitted and the time during which the Web
                      beacon is viewed. Web beacons may be invisible to the user
                      but, in general, any electronic image viewed as part of a
                      Web page or email, including HTML content, may act as a
                      Web beacon. We may use Web beacons to count the number of
                      visitors to our Web pages or track the browsing habits of
                      our users on the Lawy application and we may include Web
                      beacons in emails to count how many messages have actually
                      been opened or have prompted a reaction. We use Web
                      beacons to compile statistics about our Platform; we do
                      not link the information collected by our Web beacons to
                      the personally identifiable information you provided when
                      you registered on the Lawy application.
                    </p>
                  </div>
                  <div className="mt-9 mt-lg-13">
                    <h5 className="font-size-7 mb-7">Other Data Collection</h5>
                    <p className="font-size-5 heading-default-color">
                      When you visit the Lawy application, we may collect and
                      store certain non-personally identifiable information in
                      log files such as: the domain name, the hosting company
                      from which you access the Internet (e.g., aol.com or
                      unh.edu), the IP address of the device you are using, the
                      browser software you are using and your operating system,
                      the date and time you access the Lawy application, the
                      Website’s Internet address from which you connected to the
                      Lawy application, the Website you visit when you leave the
                      Lawy application and the number of “clicks” you have made
                      on the Lawy application. We collect this information to
                      analyze trends, manage the Lawy application and collect
                      broad demographic information for global purposes. We may
                      also determine the technology available to your browser in
                      order to offer you the most appropriate version of the Web
                      page. We may also use information collected about our
                      users to provide customized content, advertisements,
                      products and services or to improve content, navigation on
                      the Lawy application or for any other internal purpose.
                    </p>
                  </div>
                  <div className="mt-9 mt-lg-13">
                    <h5 className="font-size-7 mb-7">
                      Your Choices Regarding Disclosure and Modifications to
                      Your Personal Information
                    </h5>
                    <p className="font-size-5 heading-default-color">
                      If you do not want your personally identifiable
                      information to be used as described in this Privacy
                      Policy, please unsubscribe from the Lawy application,
                      terminate your use of it and/or close your Lawy account.
                      Even if you stop using the Lawy application or unsubscribe
                      in accordance with the foregoing and subject to the
                      provisions of the Act Respecting the Protection of
                      Personal Information in the Private Sector, personally
                      identifiable information may remain in IWN Studio’s
                      database.
                    </p>
                  </div>
                  <div className="mt-9 mt-lg-13">
                    <h5 className="font-size-7 mb-7">Notice of Amendment</h5>
                    <p className="font-size-5 heading-default-color">
                      IWN Studio reserves the right to amend this Privacy Policy
                      at any time. Only the current Privacy Policy, as presented
                      on the Lawy application, shall be deemed effective. By
                      using the Lawy application, and/or providing us with your
                      personal information, you consent and agree to the terms
                      and conditions of this Privacy Policy and any amendments
                      made by your continued use.
                    </p>
                  </div>
                  <div className="mt-9 mt-lg-13">
                    <h5 className="font-size-7 mb-7">Children’s Privacy</h5>
                    <p className="font-size-5 heading-default-color">
                      The Lawy application is not designed for children. In
                      order to protect children’s personal information, we do
                      not solicit or knowingly collect any personal information
                      from children 14 years of age or younger.
                    </p>
                  </div>
                  <div className="mt-9 mt-lg-13">
                    <h5 className="font-size-7 mb-7">Links</h5>
                    <p className="font-size-5 heading-default-color">
                      The Lawy application may contain links to sites not
                      affiliated with IWN Studio. IWN Studio is not responsible
                      for the privacy practices of any other entities or of
                      their websites and/or mobile applications.
                    </p>
                  </div>
                  <div className="mt-9 mt-lg-13">
                    <h5 className="font-size-7 mb-7">To Contact Us</h5>
                    <p className="font-size-5 heading-default-color">
                      We invite you to send us your questions and comments on
                      security and privacy protection at support@iwn.studio
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Privacy;
